<template>
  <div class="main">
      <!-- <div class="audio_box none">
      <audio ref="audio1" src="audio/gj/p6.mp3"></audio>
      <audio ref="audio2" src="audio/gj/p7.mp3"></audio>
      <audio ref="audio3" src="audio/gj/p8.mp3"></audio>
      <audio ref="audio4" src="audio/gj/p9.mp3"></audio>
      <audio ref="audio5" src="audio/gj/p10.mp3"></audio>
      <audio ref="audio6" src="audio/gj/p11.mp3"></audio>
    </div> -->
    <!-- 第6页 -->
    <div key="card6" class="card6" v-if="currentPage == 6">
      <div class="tittle">行业估值水平观察</div>
      <div class="chartWrap">
        <div class="chartTittle">2.归母净利润增长率</div>
        <div class="tipps">归母净利润增速前十行业排行</div>
        <div class="bgWrap">
          <div class="chartFlex">
            <ul class="column column1">
              <li v-for="(item, index) in cardData['产品_1']" :key="index">
                <template v-if="index == 0">
                  <img src="../../assets/img/gj/jin.png" alt="" />
                </template>
                <template v-else-if="index == 1">
                  <img src="../../assets/img/gj/yin.png" alt="" />
                </template>
                <template v-else-if="index == 2">
                  <img src="../../assets/img/gj/tong.png" alt="" />
                </template>
                <template v-else>
                  {{ index + 1 }}
                </template>
              </li>
            </ul>
            <div class="chart" id="chart1"></div>
          </div>
          <div class="chart chartBg" id="chart1Bg"></div>
        </div>
      </div>
    </div>

    <!-- 第7页 -->
    <div key="card7" class="card7" v-if="currentPage == 7">
      <div class="tittle">行业资金观察</div>
      <div class="chartWrap">
        <div class="chartTittle">
          <span>1.行业资金流向与涨跌幅观察</span>
          <span class="dateTime"> {{ dayTime }} </span>
        </div>
        <div class="tipps">近一周行业涨跌幅排行</div>
        <div class="bgWrap">
          <div class="chartFlex">
            <ul class="column column2">
              <li v-for="(item, index) in cardData['产品_2']" :key="index">
                <template v-if="index == 0">
                  <img src="../../assets/img/gj/jin.png" alt="" />
                </template>
                <template v-else-if="index == 1">
                  <img src="../../assets/img/gj/yin.png" alt="" />
                </template>
                <template v-else-if="index == 2">
                  <img src="../../assets/img/gj/tong.png" alt="" />
                </template>
                <template v-else>
                  {{ index + 1 }}
                </template>
              </li>
            </ul>
            <div class="chart" id="chart2"></div>
          </div>
          <div class="chart chartBg" id="chart2Bg"></div>
        </div>
      </div>
    </div>

    <!-- 第8页 -->
    <div key="card8" class="card8" v-if="currentPage == 8">
      <div class="tittle">行业资金观察</div>
      <div class="chartWrap">
        <div class="chartTittle">
          <span>1.行业资金流向与涨跌幅观察</span>
          <span class="dateTime"> {{ dayTime }} </span>
        </div>
        <div class="tipps">近一周主力资金流入排行（亿）</div>
        <rose-chart
          ref="refRoseChart1"
          id="refRoseChart1"
          customClass="c_rose1"
        >
        </rose-chart>
      </div>
    </div>
       <!-- 第9页 -->
    <div key="card9" class="card9" v-if="currentPage == 9">
      <div class="tittle">行业资金观察</div>
      <div class="chartWrap">
        <div class="chartTittle">
          <span>1.行业资金流向与涨跌幅观察</span>
          <span class="dateTime"> {{ dayTime }} </span>
        </div>
        <div class="tipps">近一周主力资金流出排行（亿）</div>
        <rose-chart
          ref="refRoseChart2"
          id="refRoseChart2"
          customClass="c_rose1"
        >
        </rose-chart>
      </div>
    </div>
    <!-- 第10页 -->
    <div key="card10" class="card10" v-if="currentPage == 10">
      <div class="tittle">行业资金观察</div>
      <div class="chartWrap">
        <div class="chartTittle">
          <span>2.行业融资增速和融资净买入</span>
          <span class="dateTime"> {{ dayTime }} </span>
        </div>
        <div class="tipps">近一周融资净买入排行</div>
        <div class="bgWrap">
          <div class="chartFlex">
            <ul class="column column3">
              <li v-for="(item, index) in cardData['产品_3']" :key="index">
                <template v-if="index == 0">
                  <img src="../../assets/img/gj/jin.png" alt="" />
                </template>
                <template v-else-if="index == 1">
                  <img src="../../assets/img/gj/yin.png" alt="" />
                </template>
                <template v-else-if="index == 2">
                  <img src="../../assets/img/gj/tong.png" alt="" />
                </template>
                <template v-else>
                  {{ index + 1 }}
                </template>
              </li>
            </ul>
            <div class="chart" id="chart3"></div>
          </div>
          <div class="chart chartBg" id="chart3Bg"></div>
        </div>
      </div>
    </div>

    <!-- 第11页 -->
    <div key="card11" class="card11" v-if="currentPage == 11">
      <div class="mounce"></div>
      <div class="tone">
        <div class="news_tittle"></div>
        <div class="news_content1">
          <anime-txt
            ref="news1"
            id="news1"
            class="anime_txt"
            :txt="news.one"
            duration="1000"
            easing="easeOutExpo"
            staggerSpeed="veryFast"
            inEffect="typeIn"
            outEffect="fadeOut"
          />
        </div>
        <div class="line1"></div>
        <div class="news_tittle2">免责声明</div>
        <div class="news_content2">
          <anime-txt
            ref="news2"
            id="news2"
            class="anime_txt"
            :txt="news.two"
            duration="1000"
            easing="easeOutExpo"
            staggerSpeed="veryFast"
            inEffect="typeIn"
            outEffect="fadeOut"
          />
        </div>
        <div class="line2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import animeTxt from "@/components/animeTxt/index.vue";
import moment from "moment";
import { sleep } from "@/utils/common";
import roseChart from "@/components/roseChart/index.vue";
export default {
  components: {
    roseChart,
    //  barChartHorizen
    animeTxt,
  },
  data: function () {
    return {
      currentPage: 6,
      dayTime: moment().format("YYYY-MM-DD"),
      population1: [
        { name: "房地产", value: 446 },
        { name: "建筑装饰", value: 936 },
        { name: "钢铁", value: 323 },
        { name: "航运", value: 336 },
        { name: "石油", value: 236 },
        { name: "酒店餐饮", value: 256 },
        { name: "贸易", value: 127 },
        { name: "白酒", value: 227 },
        { name: "港口", value: 67 },
        { name: "其他建材", value: 227 },
      ],
        population2: [
        { name: "电子元器件", value: 342 },
        { name: "电气设备", value: 436 },
        { name: "有色金属", value: 823 },
        { name: "化学制品", value: 338 },
        { name: "汽车", value: 231 },
        { name: "酿酒饮料", value: 252 },
        { name: "软件开发及服务", value: 137 },
        { name: "医疗器械服务", value: 224 },
        { name: "医药制造", value: 62},
        { name: "国防军工", value: 221 },
      ],
      news: {
        one: "继续布局第三季度确定性较强的行业，如新能源、储能，以及双十一或将带动大的消费，如医美、白酒、调味品等。",
        two: "市场有风险，投资需谨慎。本平台部分资讯内容、数据来源于公开信息或第三方，不代表国泰君安观点，本公司对其真实性、准确性、完整性和及时性不作任何保证。本资讯内容仅供参考，不构成对所述证券买卖的意见。本资讯内容不应作为投资决策的唯一参考因素，投资者需自主作出投资决策并独立承担投资风险。",
      },
      randomArr: [
        [36.7, 30.1, 15.8, 11.7, 11.2, 9.4, 9.4, 7.5, 8.3, 6.1],
        [31.1, 26.2, 15.4, 11.3, 10.6, 9.2, 9.8, 17.4, 8.4, 5.7],
        [28.2, 20.6, 15, 11, 10.9, 9.5, 9.5, 25.9, 7.9, 5.3],
        [26.5, 19.5, 15.3, 11.5, 11.3, 9.2, 10, 27.3, 8.3, 5.7],
        [20.7, 18.4, 15.1, 11.2, 11.1, 9.4, 9.6, 29.9, 7.6, 5.9],
        [15.9, 20.8, 15.4, 11, 10.6, 9.2, 9.3, 37.2, 8.1, 5.7],
      ],
       randomArr2: [
        [66.7, 80.1, 15.8, 11.7, 11.2, 9.4, 9.4, 17.5, 8.3, 6.1],
        [41.1, 76.2, 15.4, 11.3, 10.6, 9.2, 9.8, 27.4, 8.4, 5.7],
        [28.2, 70.6, 15, 11, 10.9, 9.5, 9.5, 45.9, 7.9, 5.3],
        [26.5, 79.5, 15.3, 11.5, 11.3, 9.2, 10, 57.3, 8.3, 5.7],
        [20.7, 78.4, 15.1, 11.2, 11.1, 9.4, 9.6, 69.9, 7.6, 5.9],
        [15.9, 60.8, 15.4, 11, 10.6, 9.2, 9.3, 87.2, 8.1, 5.7],
      ],
      cardData: {
        产品_1: [
          "交通运输",
          "石油石化",
          "有色金属",
          "钢铁",
          "基础化工",
          "纺织服饰",
          "纺织服饰",
          "电子",
          "煤炭",
          "社会服务",
        ],
        涨跌幅_1: [
          "20.4",
          "16.2",
          "15.0",
          "11.7",
          "10.4",
          "9.7",
          "9.1",
          "8.0",
          "7.5",
          "6.2",
        ],
        产品_2: [
          "房地产",
          "非银金融",
          "家用电器",
          "银行",
          "建筑材料",
          "计算机",
          "通信",
          "汽车",
          "纺织服饰",
          "社会服务",
        ],
         涨跌幅_2: [
          40.4,
          36.2,
          25.0,
          21.7,
          15.4,
          12.7,
          10.1,
          8.0,
          7.5,
          6.2,
        ],
        产品_3: [
          "传媒",
          "家用电器",
          "纺织服饰",
          "农林牧渔",
          "煤炭",
          "通信",
          "轻工制造",
          "石油石化",
          "房地产",
          "美容护理",
        ],
        涨跌幅_3: [
          70.4,
          32.2,
          29.0,
          22.7,
          13.4,
          10.7,
          15.1,
          18.0,
          9.5,
          6.2,
        ],
      },
      myChart: null,
      barChartOption: {
        grid: {
          left: "115px",
          right: "90px",
          top: "0",
          bottom: "0",
        },
        animationDuration: 3000,
        animationDurationUpdate: 1000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
        xAxis: [
          {
            type: "value",
            position: "bottom",
            splitLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#999",
                fontSize: 18,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            data: [],
            inverse: true,
            animationDuration: 300,
            animationDurationUpdate: 300,
            axisLabel: {
              margin: 100,
              align: "left",
              textStyle: {
                fontSize: 21,
                color: function (value, index) {
                  return index < 3 ? "red" : "#333";
                },
              },
            },
          },
        ],
        series: [
          {
            realtimeSort: true,
            type: "bar",
            data: [],
            barWidth: 24,
            stack: "total",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(0,0,0,.1)",
              borderRadius: [0, 20, 20, 0],
            },
            label: {
              show: true,
              formatter: "{c}%",
              fontSize: 23,
              color: "#3372EA",
              valueAnimation: true,
              //   position: 'right',
              margin: 20,
            },
            labelLayout(params) {
              return {
                x: params.rect.x + 370,
                y: params.rect.y + params.rect.height / 2,
                verticalAlign: "middle",
                align: "left",
              };
            },
            itemStyle: {
              normal: {
                color: "#2A4DCF",
                borderRadius: [0, 20, 20, 0],
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    // 页面card一个接一个播放
    async playCardStart() {
      console.log("playCardStart..");
      await this.page6Fn();
      await sleep(1000);
      this.currentPage = 7;
      await sleep(100);
      await this.page7Fn();
      await sleep(1000);
      this.currentPage = 8;
      await sleep(100);
      await this.page8Fn();
      await sleep(1000);
       this.currentPage = 9;
      await sleep(100);
      await this.page9Fn();
      await sleep(1000);
      this.currentPage = 10;
      await sleep(100);
      await this.page10Fn();
      await sleep(1000);
      this.currentPage = 11;
      await sleep(100);
      await this.page11Fn();
      await sleep(1000);
    },
    // 第6页
    async page6Fn() {
      console.log("page6Fn...");
      //   this.$refs.audio11.play()
      //   this.$refs.refAnimeTxt6.playIn();
      // this.$refs.audio1.play();
      $(".tittle").css({ opacity: 1 });
      $(".tittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartWrap").css({ opacity: 1 });
      $(".chartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartTittle").css({ opacity: 1 });
      $(".chartTittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".tipps").css({ opacity: 1 });
      $(".tipps").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.loadChart(1);
      await sleep(4000);
    },
    // 第7页
    async page7Fn() {
      // this.$refs.audio2.play();
      console.log("page7Fn...");
      $(".tittle").css({ opacity: 1 });
      $(".tittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartWrap").css({ opacity: 1 });
      $(".chartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartTittle").css({ opacity: 1 });
      $(".chartTittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".tipps").css({ opacity: 1 });
      $(".tipps").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.loadChart(2);
      await sleep(1000);
      this.barChartShafer("涨跌幅_2");
      await sleep(7000);
    },
    // 第8页
    async page8Fn() {
        // this.$refs.audio3.play();
      console.log("page8Fn...");
      $(".tittle").css({ opacity: 1 });
      $(".tittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartWrap").css({ opacity: 1 });
      $(".chartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartTittle").css({ opacity: 1 });
      $(".chartTittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".tipps").css({ opacity: 1 });
      $(".tipps").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.$refs["refRoseChart1"].DonutChart(this.population1, {
        name: (d) => d.name,
        value: (d) => d.value,
        width: 720,
        height: 900,
        donutRatioRange: [0.2, 0.3, 0.6], //空心内圈的半径占比，最小半径占比和最大半径占比
        labelOffset:50,
        top3Offset:80,
       colors: [
          "#ef738f",
          "#ef837e",
          "#ee9273",
          "#efa869",
          "#eeb55b",
          "#eed454",
          "#e8d080",
          "#eec1a2",
          "#efc2a3",
          "#ddafbb",
        ],
        duration: 1000,
        delay: 80,
        ease: "easeBackOut",
        valueType: "absolute",
        valueSuffix: "亿",
        isSort: true,
        isSameAngle: true,
      });
       await sleep(7000);
    },
     // 第9页
    async page9Fn() {
      //  this.$refs.audio4.play();
      console.log("page9Fn...");
      $(".tittle").css({ opacity: 1 });
      $(".tittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartWrap").css({ opacity: 1 });
      $(".chartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartTittle").css({ opacity: 1 });
      $(".chartTittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".tipps").css({ opacity: 1 });
      $(".tipps").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.$refs["refRoseChart2"].DonutChart(this.population2, {
        name: (d) => d.name,
        value: (d) => d.value,
        width: 720,
        height: 900,
         donutRatioRange: [0.2, 0.3, 0.6], //空心内圈的半径占比，最小半径占比和最大半径占比
        labelOffset:60,
        top3Offset:80,
        colors: [
          "#4ae3ff",
          "#5cd3ff",
          "#6ac4ff",
          "#7ab6ff",
          "#89a8ff",
          "#9a96ff",
          "#aa88ff",
          "#b978ff",
          "#cd69ff",
          "#da5aff",
        ],
        duration: 1000,
        delay: 80,
        ease: "easeBackOut",
        valueType: "absolute",
        valueSuffix: "亿",
        isSort: true,
        isSameAngle: true,
      });
       await sleep(8000);
    },
    // 第10页
    async page10Fn() {
      //  this.$refs.audio5.play();
      console.log("page10Fn...");
      $(".tittle").css({ opacity: 1 });
      $(".tittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartWrap").css({ opacity: 1 });
      $(".chartWrap").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".chartTittle").css({ opacity: 1 });
      $(".chartTittle").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".tipps").css({ opacity: 1 });
      $(".tipps").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      this.loadChart(3);
      await sleep(1000);
      this.barChartShafer("涨跌幅_3");
      await sleep(7000);
    },
    // 第11页
    async page11Fn() {
      // this.$refs.audio6.play();
      console.log("page11Fn...");
      $(".mounce").css({ opacity: 1 });
      $(".mounce").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".tone").css({ opacity: 1 });
      $(".tone").addClass("shadowSlideDown");
      await sleep(500);
      $(".news_tittle").css({ opacity: 1 });
      $(".news_tittle").addClass("animate__animated animate__bounceIn ");
      await sleep(500);
      this.$refs.news1.playIn();
      await sleep(2000);
      $(".line1").css({ opacity: 1 });
      $(".line1").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
      $(".news_tittle2").css({ opacity: 1 });
      $(".news_tittle2").addClass("animate__animated animate__bounceIn ");
      await sleep(500);
      this.$refs.news2.playIn();
      await sleep(4000);
      $(".line2").css({ opacity: 1 });
      $(".line2").addClass("animate__animated animate__fadeIn ");
      await sleep(500);
    },
    // 龙虎榜-营业部净买入个股
    loadChart(idx) {
      // 基于准备好的dom，初始化this.$echarts实例
      const className = "chart" + idx;
      const classBgName = "chart" + idx + "Bg";
      this.myChart = this.$echarts.init(document.getElementById(className));
      this.myChartBg = this.$echarts.init(document.getElementById(classBgName));
      // 指定图表的配置项和数据
      var option = this.refreshOption(idx);
      var optionBg = this.creatBgOption(idx);
      // 使用刚指定的配置项和数据显示图表。
      this.myChartBg.setOption(optionBg);
      this.myChart.setOption(option);
    },
    creatBgOption(idx) {
      var option = _.cloneDeep(this.barChartOption);
      const tempData = this.cardData;
      const label = "产品_" + idx;
      let bgLabelArr = [];
      let bgValueArr = [];
      tempData[label].map((item) => {
        bgLabelArr.push("");
        bgValueArr.push(0);
      });
      option.yAxis[0].data = bgLabelArr;
      option.series[0].data = bgValueArr;
      option.series[0].label = false;
      if (idx != 1) {
        //动态变化的拉长
        option.grid.right = "10px";
      }
      return option;
    },
    refreshOption(idx) {
      var option = _.cloneDeep(this.barChartOption);
      const tempData = this.cardData;
      const label = "产品_" + idx;
      const value = "涨跌幅_" + idx;
      option.yAxis[0].data = tempData[label];
      option.series[0].data = tempData[value];
      option.series[0].showBackground = false;
      if (idx != 1) {
        //动态变化不定位数值
        option.series[0].label.position = "right";
        option.series[0].label.padding = [0, 0, 0, 10];
        option.animationDuration=1000
        delete option.series[0].labelLayout;
      }
      if (idx == 3) {
        option.series[0].label.formatter = "{c} 亿";
      }
      this.showBarIdx(idx);
      return option;
    },
    showBarIdx(idx) {
      const childClassName = ".column" + idx + " li";
      let liIdx = 0;
      let timer;
      timer = setInterval(function () {
        if (liIdx >= $(childClassName).length) {
          clearInterval(timer);
          timer = null;
        } else {
          $(childClassName).eq(liIdx).css({ opacity: 1 });
          $(childClassName)
            .eq(liIdx)
            .addClass("animate__animated animate__bounceIn");
          liIdx++;
        }
      }, 100);
    },
    barChartShafer(key) {
      let refreshIdx = 0;
      let _this = this;
      let timer = setInterval(function () {
        console.log(refreshIdx);
        if (refreshIdx >= 6) {
          clearInterval(timer);
          timer = null;
        } else {
          _this.dayTime = moment()
            .subtract(refreshIdx + 1, "days")
            .format("YYYY-MM-DD");
            if(key.indexOf(3)>-1){
                 _this.cardData[key] = _this.randomArr2[refreshIdx];
            }else{
                 _this.cardData[key] = _this.randomArr[refreshIdx];
            }
        //   console.log(_this.cardData[key]);
          _this.myChart.setOption({
            animationDuration: 0,
            series: [
              {
                type: "bar",
                data: _this.cardData[key],
              },
            ],
          });
          refreshIdx++;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang='less'>
.main {
  background: url(../../assets/img/gj/gj_bg.png) no-repeat;
  width: 720px;
  height: 1280px;
}
.audio_box{
  height: 0;
  width:0;
  line-height: 0;
}
.tittle {
  width: 333px;
  height: 55px;
  background: url(../../assets/img/gj/tittleBg.png) no-repeat;
  color: #fff;
  font-size: 30px;
  text-align: center;
  line-height: 55px;
  position: absolute;
  top: 41px;
  left: 30px;
  opacity: 0;
}
.chartWrap {
  background: #fff;
  width: 658px;
  height: 1067px;
  border-radius: 12px;
  left: 30px;
  top: 115px;
  position: absolute;
  opacity: 0;
  .chartTittle {
    background: #2a4dcf;
    color: #fff;
    font-size: 30px;
    line-height: 60px;
    padding: 0 25px;
    margin: 20px;
    border-radius: 5px;
    opacity: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dateTime {
      font-size: 20px;
    }
  }
  .tipps {
    color: rgba(42, 77, 207, 0.56);
    font-size: 28px;
    margin: 26px 38px;
    opacity: 0;
  }
}
.chartFlex {
  display: flex;
  padding: 0 20px;
  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 685px;
    font-size: 27px;
    li {
      flex: 1;
      padding-top: 17px;
      opacity: 0;
    }
  }
  .chart {
    width: 560px;
  }
}
.bgWrap {
  position: relative;
}
.chartBg {
  position: absolute;
  width: 560px;
  height: 685px;
  left: 60px;
  top: 0;
}
.c_rose1 {
  position: absolute;
  left: -63px;
  top: 86px;
  /deep/ .donut_txt1 {
    font-size: 23px;
  }
  /deep/ .donut_txt2 {
    font-size: 23px;
  }
  /deep/ .top3{
      font-size: 30px;
  }
}
.mounce {
  position: absolute;
  width: 660px;
  height: 135px;
  left: 30px;
  top: 67px;
  opacity: 0;
  background: url(../../assets/img/gj/news_mouce.png) no-repeat;
}
.tone {
  position: absolute;
  width: 537px;
  height: 0;
  background: url(../../assets/img/gj/news_paper.png) no-repeat;
  left: 92px;
  top: 134px;
  opacity: 0;
}
.news_tittle {
  position: absolute;
  width: 236px;
  height: 85px;
  background: url(../../assets/img/gj/news_words.png) no-repeat;
  left: 158px;
  top: 46px;
  opacity: 0;
}
.news_tittle2 {
  position: absolute;
  width: 186px;
  height: 74px;
  background: url(../../assets/img/gj/news_tittle.png) no-repeat;
  left: 173px;
  top: 420px;
  text-align: center;
  line-height: 74px;
  color: #2a4dcf;
  font-size: 30px;
  opacity: 0;
}
.news_content1 {
  position: absolute;
  left: 50px;
  top: 224px;
  width: 451px;
  height: 154px;
  font-size: 27px;
  color: #2a4dcf;
  font-weight: 500;
  line-height: 38px;
}
.news_content2 {
  position: absolute;
  width: 453px;
  height: 380px;
  left: 50px;
  top: 521px;
  font-size: 27px;
  font-weight: 400;
  color: #2a4dcf;
  line-height: 38px;
}
.line1 {
  height: 0;
  width: 457px;
  position: absolute;
  left: 50px;
  top: 389px;
  opacity: 0;
  border-bottom: dashed 1px #2a4dcf;
}
.line2 {
  height: 0;
  width: 457px;
  position: absolute;
  left: 50px;
  top: 882px;
  opacity: 0;
  border-bottom: dashed 1px #2a4dcf;
}
.shadowSlideDown {
  animation: shadowSlideDownAnimate 2s;
  animation-fill-mode: both;
}
@keyframes shadowSlideDownAnimate {
  0% {
    height: 0;
  }
  100% {
    height: 917px;
  }
}
</style>