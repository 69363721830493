<template>
  <div class="width-100 height-100 content">
    <!-- <div class="audio_box none">
      <audio ref="audio1" src="audio/gj/1.mp3"></audio>
      <audio ref="audio2" src="audio/gj/2.mp3"></audio>
      <audio ref="audio3" src="audio/gj/3.mp3"></audio>
      <audio ref="audio4" src="audio/gj/4.mp3"></audio>
      <audio ref="audio5" src="audio/gj/5.mp3"></audio>
      <audio ref="audio6" src="audio/gj/6.mp3"></audio>
      <audio ref="audio7" src="audio/gj/7.mp3"></audio>
      <audio ref="audio8" src="audio/gj/8.mp3"></audio>
    </div> -->
    <div class="card1 animate__animated" v-show="currentIndex == 1">
      <div class="text1 none">
        <span class="line"></span>
        <span>行业点睛</span>
      </div>
      <div class="img_box none">
        <img src="../../assets/img/gj/gj_text.png" alt="" />
      </div>
      <div class="text2 none">
        <div class="txt1">汪海溯_S0880618020011</div>
        <div class="txt2">2021/10/22</div>
      </div>
      <div class="text3 none">内容摘要</div>
      <div class="text4">
        <ul>
          <li class="none">
            <div class="circle1 circle"></div>
            近期市场震荡整固，上一期行业点睛推荐的农林牧渔
            和银行股有阶段性表现。
          </li>
          <li class="none">
            <div class="circle2 circle"></div>
            从资金流向来看，电气设备、有色、采掘、化工、银行、汽车等板块获得资金大幅流入。
          </li>
          <li class="none">
            <div class="circle3 circle"></div>
            短期建议“轻指数、重个股”，积极把握好三季度报业
            绩表现较优的个股行情。
          </li>
          <li class="none">
            <div class="circle4 circle"></div>
            宏观经济层面仍然不利于市场持续拉升，“类滞涨”环 境加大操作难度。
          </li>
          <li class="none">
            <div class="circle5 circle"></div>
            继续布局确定与景气度交叠概念，比如新能源、储能。
          </li>
          <li class="none">
            <div class="circle6 circle"></div>
            双十一或带动消费股有不错表现，可以关注医美、白酒、调味品等。
          </li>
        </ul>
      </div>
      <div class="tips">
        <img src="../../assets/img/gj/gj_tips.png" alt="" />
        <span>记得点下关注，投资少走“弯路”</span>
      </div>
    </div>
    <div
      class="card2 card animate__animated common_box"
      v-show="currentIndex == 2"
    >
      <div class="img_box none">
        <img src="../../assets/img/gj/gj_t_box.png" alt="" />
        <div class="title none" id="title">一周市场资金动态</div>
      </div>
      <div class="content_box">
        <div class="title1 none" id="title1">1.近期两市成交额（亿元）</div>
        <bar-chart ref="refBarChart1" id="refBarChart1" customClass="c_bar1">
        </bar-chart>
        <div class="title2 chart2 none">2.近期涨跌停家数（家）</div>
        <div class="le_title none">
          <div class="le1">
            <span class="squr"></span>
            <span>涨停</span>
          </div>
          <div class="le2">
            <span class="squr squr2"></span>
            <span>跌停</span>
          </div>
        </div>
        <div class="chart3" id="chart3" ref="chart3"></div>
      </div>
    </div>
    <div
      class="card3 card animate__animated common_box"
      v-show="currentIndex == 3"
    >
      <div class="img_box none">
        <img src="../../assets/img/gj/gj_t_box.png" alt="" />
        <div class="title none" id="title2">市场估值水平概况</div>
      </div>
      <div class="content_box">
        <div class="title1 none" id="title1">1.全部A股平均市盈率</div>
        <div class="leng leng1 none">
          <div class="left">
            <div class="img_box1">
              <img src="../../assets/img/gj/gj_icon1.png" alt="" />
              全部A股平均市盈率
            </div>
          </div>
          <div class="right">
            <div class="img_box2">
              <img src="../../assets/img/gj/gj_icon2.png" alt="" />
              历史中位数
            </div>
            <div class="img_box3">
              <img src="../../assets/img/gj/gj_icon3.png" alt="" />
              合理估值上限
            </div>
            <div class="img_box4">
              <img src="../../assets/img/gj/gj_icon4.png" alt="" />
              合理估值下限
            </div>
          </div>
        </div>
        <div class="chart4" id="chart4"></div>
        <div class="line line_red"></div>
        <div class="line line_blue none"></div>
        <div class="line line_green none"></div>
        <div class="title2 chart2 none">2.沪深300市盈率</div>
        <div class="leng leng2 none">
          <div class="left">
            <div class="img_box1">
              <img src="../../assets/img/gj/gj_icon5.png" alt="" />
              沪深300市盈率
            </div>
          </div>
          <div class="right">
            <div class="img_box2">
              <img src="../../assets/img/gj/gj_icon2.png" alt="" />
              历史中位数
            </div>
            <div class="img_box3">
              <img src="../../assets/img/gj/gj_icon3.png" alt="" />
              合理估值上限
            </div>
            <div class="img_box4">
              <img src="../../assets/img/gj/gj_icon4.png" alt="" />
              合理估值下限
            </div>
          </div>
        </div>
        <div class="chart5" id="chart5"></div>
        <div class="line line_red2"></div>
        <div class="line line_blue2"></div>
        <div class="line line_green2"></div>
      </div>
    </div>
    <div
      class="card4 card animate__animated common_box"
      v-show="currentIndex == 4"
    >
      <div class="img_box none">
        <img src="../../assets/img/gj/gj_t_box.png" alt="" />
        <div class="title none" id="title2">市场估值水平概况</div>
      </div>
      <div class="content_box">
        <div class="title1 none" id="title1">3.科创板市盈率</div>
        <div class="leng leng1 none">
          <div class="left">
            <div class="img_box1">
              <img src="../../assets/img/gj/gj_icon1.png" alt="" />
              科创板市盈率市盈率
            </div>
          </div>
          <div class="right">
            <div class="img_box2">
              <img src="../../assets/img/gj/gj_icon2.png" alt="" />
              历史中位数
            </div>
            <div class="img_box3">
              <img src="../../assets/img/gj/gj_icon3.png" alt="" />
              合理估值上限
            </div>
            <div class="img_box4">
              <img src="../../assets/img/gj/gj_icon4.png" alt="" />
              合理估值下限
            </div>
          </div>
        </div>
        <div class="chart6_1" id="chart6_1"></div>
        <div class="line line_red"></div>
        <div class="line line_blue none"></div>
        <div class="line line_green none"></div>
        <div class="title2 chart2 none">4.创业板平均市盈率</div>
        <div class="leng leng2 none">
          <div class="left">
            <div class="img_box1">
              <img src="../../assets/img/gj/gj_icon5.png" alt="" />
              创业板平均市盈率
            </div>
          </div>
          <div class="right">
            <div class="img_box2">
              <img src="../../assets/img/gj/gj_icon2.png" alt="" />
              历史中位数
            </div>
            <div class="img_box3">
              <img src="../../assets/img/gj/gj_icon3.png" alt="" />
              合理估值上限
            </div>
            <div class="img_box4">
              <img src="../../assets/img/gj/gj_icon4.png" alt="" />
              合理估值下限
            </div>
          </div>
        </div>
        <div class="chart6" id="chart6"></div>
        <div class="line line_red2"></div>
        <div class="line line_blue2"></div>
        <div class="line line_green2"></div>
      </div>
    </div>
    <div
      class="card5 card animate__animated common_box"
      v-show="currentIndex == 5"
    >
      <div class="img_box none">
        <img src="../../assets/img/gj/gj_t_box.png" alt="" />
        <div class="title none" id="title4">行业估值水平观察</div>
      </div>
      <div class="content_box">
        <div class="title1 none" id="title5">1.行业估值</div>
        <div class="len none">
          <span class="len_icon"></span>
          估值合理区间
        </div>
        <div class="chart7" id="chart7"></div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import barChart from "@/components/barChart/index.vue";
export default {
  name: "OneComponent",
  data: function () {
    return {
      currentIndex: 1,
      alphabet: [
        { letter: "10-15", frequency: 9894.62 },
        { letter: "10-18", frequency: 10026.48 },
        { letter: "10-19", frequency: 9735.91 },
        { letter: "10-20", frequency: 10205.67 },
        { letter: "10-21", frequency: 9935.36 },
      ],
    };
  },
  components: { barChart },
  props: ["cardData"],
  computed: {},
  methods: {
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    playIn1(refName) {
      this.$refs[refName].BarChart(this.alphabet, {
        x: (d) => d.letter,
        y: (d) => d.frequency,
        yLabel: "亿元",
        width: 700,
        height: 350,
        marginLeft: 60,
        yDomain: [9000, 10300],
        duration: 600,
        xPadding: 0.48,
        delay: 60,
        ease: "easeBack",
      });
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.currentIndex = 1;
      await this.playPage1();
      this.currentIndex = 2;
      await this.playPage2();
      this.currentIndex = 3;
      await this.playPage3();
      this.currentIndex = 4;
      await this.playPage4();
      this.currentIndex = 5;
      this.playPage5();
    },
    async playPage1() {
      // this.$refs.audio1.play();
      $(".text1").removeClass("none");
      $(".text1").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".img_box").removeClass("none");
      $(".img_box").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".text2").removeClass("none");
      $(".text2").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".text3").removeClass("none");
      $(".text3").addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(0).removeClass("none");
      $(".text4 li").eq(0).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(1).removeClass("none");
      $(".text4 li").eq(1).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(2).removeClass("none");
      $(".text4 li").eq(2).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(3).removeClass("none");
      $(".text4 li").eq(3).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(4).removeClass("none");
      $(".text4 li").eq(4).addClass("animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".text4 li").eq(5).removeClass("none");
      $(".text4 li").eq(5).addClass("animate__animated animate__fadeInLeft");
      await sleep(5000);
      $(".card1").addClass("animate__animated animate__fadeOut");
    },
    async playPage2() {
      // this.$refs.audio2.play();
      $(".card2 .img_box").removeClass("none");
      $(".card2 .img_box").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card2 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title");
      await sleep(1000);
      $(".card2 .title1").removeClass("none");
      $(".card2 .title1").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.playIn1("refBarChart1");
      await sleep(2000);
      // this.$refs.audio3.play();
      $(".card2 .title2").removeClass("none");
      $(".card2 .title2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card2 .le_title").removeClass("none");
      $(".card2 .le_title").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.loadChart3();
      await sleep(2000);
      $(".card2").addClass("animate__animated animate__fadeOut");
    },
    async playPage3() {
      // this.$refs.audio4.play();
      $(".card3 .img_box").removeClass("none");
      $(".card3 .img_box").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card3 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title2");
      await sleep(1000);
      $(".card3 .title1").removeClass("none");
      $(".card3 .title1").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card3 .leng1").removeClass("none");
      $(".card3 .leng1").addClass(" animate__animated animate__fadeIn");
      await sleep(1000);
      this.loadChart4();
      await sleep(1000);
      $(".card3 .line_red").removeClass("none");
      $(".card3 .line_red").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .line_blue").removeClass("none");
      $(".card3 .line_blue").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .line_green").removeClass("none");
      $(".card3 .line_green").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .title2").removeClass("none");
      $(".card3 .title2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      // this.$refs.audio5.play();
      $(".card3 .leng2").removeClass("none");
      $(".card3 .leng2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.loadChart5();
      await sleep(1000);
      $(".card3 .line_red2").removeClass("none");
      $(".card3 .line_red2").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .line_blue2").removeClass("none");
      $(".card3 .line_blue2").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card3 .line_green2").removeClass("none");
      $(".card3 .line_green2").animate({ width: "540px" }, 1000);
      await sleep(2000);
      $(".card3").addClass("animate__animated animate__fadeOut");
    },
    async playPage4() {
      // this.$refs.audio6.play();
      $(".card4 .img_box").removeClass("none");
      $(".card4 .img_box").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card4 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title2");
      await sleep(1000);
      $(".card4 .title1").removeClass("none");
      $(".card4 .title1").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card4 .leng1").removeClass("none");
      $(".card4 .leng1").addClass(" animate__animated animate__fadeIn");
      await sleep(1000);
      this.loadChart6_1();
      await sleep(1000);
      $(".card4 .line_red").removeClass("none");
      $(".card4 .line_red").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card4 .line_blue").removeClass("none");
      $(".card4 .line_blue").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card4 .line_green").removeClass("none");
      $(".card4 .line_green").animate({ width: "540px" }, 1000);
      await sleep(1000);
      // this.$refs.audio7.play();
      $(".card4 .title2").removeClass("none");
      $(".card4 .title2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card4 .leng2").removeClass("none");
      $(".card4 .leng2").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      this.loadChart6();
      await sleep(1000);
      $(".card4 .line_red2").removeClass("none");
      $(".card4 .line_red2").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card4 .line_blue2").removeClass("none");
      $(".card4 .line_blue2").animate({ width: "540px" }, 1000);
      await sleep(1000);
      $(".card4 .line_green2").removeClass("none");
      $(".card4 .line_green2").animate({ width: "540px" }, 1000);
      await sleep(2000);
      $(".card4").addClass("animate__animated animate__fadeOut");
    },
    async playPage5() {
      // this.$refs.audio8.play();
      $(".card5 .img_box").removeClass("none");
      $(".card5 .img_box").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card5 #title4").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title4");
      // await sleep(1000);
      $(".card5 #title5").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title5");
      // await sleep(1000);
      $(".card5 .len").removeClass("none");
      $(".card5 .len").addClass(" animate__animated animate__fadeInRight");
      await sleep(1000);
      this.loadChart7();
      await sleep(6000);
      $(".card5").addClass("animate__animated animate__fadeOut");
      this.$emit("playEnd");
    },

    loadChart3() {
      let myChart = this.$echarts.init(document.getElementById("chart3"));
      let option = (option = {
        grid: {
          left: "50",
          right: "50",
          bottom: "50",
          top: "20",
        },
        xAxis: {
          type: "category",
          // boundaryGap: [0, 0.01],
          data: ["10-15", "10-18", "10-19", "10-20", "10-21"],
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            fontSize: 13,
            color: "#000",
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          position: "left",
          splitLine: {
            show: false,
            lineStyle: {
              color: "#3D3D3D",
              type: "dashed",
            },
          },
          axisLabel: {
            show: true,
            fontSize: 13,
            color: "#000",
            formatter: function (value) {
              return value.toFixed(2);
            },
          },
        },
        series: [
          {
            name: "vtDateValue",
            type: "bar",
            data: [12, 45, 30, 20, 16],
            itemStyle: {
              color: "#5B8FF9",
            },
          },
          {
            name: "vtDateValueAvg",
            type: "bar",
            data: [32, 34, 16, 13, 32, 40],
            itemStyle: {
              color: "#5AD8A6",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart4() {
      let myChart = this.$echarts.init(document.getElementById("chart4"));
      let option = (option = {
        xAxis: {
          type: "category",
          data: [
            "2021/1/29",
            "2021/2/26",
            "2021/3/31",
            "2021/4/30",
            "2021/5/31",
            "2021/6/30",
            "2021/7/30",
            "2021/8/31",
            "2021/9/30",
            "2021/10/21",
            "2021/10/12",
            "2021/10/23",
          ],
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 10,
          left: 30,
          right: 30,
        },
        yAxis: {
          type: "value",
          show: true,
          min: 15,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          position: "left",
          splitLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "TTM1",
            data: [
              24.17, 19.44, 17.76, 22.16, 18.52, 25.68, 16.26, 17.05, 18.67,
              23.0, 19.44, 17.76, 22.16,
            ],
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FFAD01",
              lineStyle: {
                width: 2,
              },
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart5() {
      let myChart = this.$echarts.init(document.getElementById("chart5"));
      let option = (option = {
        xAxis: {
          type: "category",
          data: [
            "2021/1/29",
            "2021/2/26",
            "2021/3/31",
            "2021/4/30",
            "2021/5/31",
            "2021/6/30",
            "2021/7/30",
            "2021/8/31",
            "2021/9/30",
            "2021/10/21",
            "2021/10/12",
            "2021/10/23",
          ],
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 20,
          left: 30,
          right: 30,
        },
        yAxis: {
          type: "value",
          show: true,
          min: 14,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          position: "left",
          splitLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "TTM1",
            data: [
              22.0, 16.44, 15.0, 22.16, 15.17, 18.44, 17.0, 22.16, 18.52, 15.0,
              20.26, 18.05, 20.67,
            ],
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#1890FF",
              lineStyle: {},
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart6_1() {
      let myChart = this.$echarts.init(document.getElementById("chart6_1"));
      let option = (option = {
        xAxis: {
          type: "category",
          data: [
            "2021/1/29",
            "2021/2/26",
            "2021/3/31",
            "2021/4/30",
            "2021/5/31",
            "2021/6/30",
            "2021/7/30",
            "2021/8/31",
            "2021/9/30",
            "2021/10/21",
            "2021/10/12",
            "2021/10/23",
          ],
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 20,
          left: 30,
          right: 30,
        },
        yAxis: {
          type: "value",
          show: true,
          min: 15,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          position: "left",
          splitLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
              lineStyle: {},
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "TTM1",
            data: [
              22.0, 19.44, 17.0, 22.16, 24.0, 19.44, 16.0, 22.16, 18.52, 22.0,
              23.26, 18.05, 20.67,
            ],
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FFAD01",
              lineStyle: {},
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart6() {
      let myChart = this.$echarts.init(document.getElementById("chart6"));
      let option = (option = {
        xAxis: {
          type: "category",
          data: [
            "2021/1/29",
            "2021/2/26",
            "2021/3/31",
            "2021/4/30",
            "2021/5/31",
            "2021/6/30",
            "2021/7/30",
            "2021/8/31",
            "2021/9/30",
            "2021/10/21",
            "2021/10/12",
            "2021/10/23",
          ],
          axisTick: {
            show: false,
          },
        },
        grid: {
          bottom: 30,
          top: 10,
          left: 30,
          right: 30,
        },
        yAxis: {
          type: "value",
          show: true,
          min: 70,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          position: "left",
          splitLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#707070",
              lineStyle: {},
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "TTM1",
            data: [101, 96, 87, 75, 100, 83, 81, 77, 102, 82, 89, 84],
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#1890FF",
            },
          },
        ],
      });
      myChart.setOption(option);
    },
    loadChart7() {
      let myChart = this.$echarts.init(document.getElementById("chart7"));
      let optionData = {
        label: ["纺织服装","",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",],
        Placeholder: [330],
        Income: [180],
        label2:["纺织服装",
          "房地产",
          "非银金融",
          "钢铁",
          "公用事业",
          "国防军工",
          "互联网",
          "机械设备",
          "计算机",
          "家用电器",
          "建筑材料",
          "交通运输",],
        Placeholder2: [
          330, 580, 943, 429, 295, 759, 377, 688, 855, 494, 791, 599, 
        ],
        Income2: [
          180, 150, 125, 180, 170, 160, 180, 190, 150, 110, 160, 130, 
        ],
      };
      let option = (option = {
        grid: {
          left: "3%",
          right: "6%",
          bottom: "3%",
          containLabel: true,
        },
        animationDuration: 300,
        animationDurationUpdate: 300,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
        yAxis: {
          type: "category",
          show: true,
          inverse: true,
          data: optionData.label,
          animationDuration: 300,
          animationDurationUpdate: 300,
          axisLabel: {
            show: true,
            align:"left",
            color: "#333333",
            fontSize: 24,
            padding:-110
          },
        },
        xAxis: {
          type: "value",
          splitNumber: 4,
          axisLabel: {
            show: true,
            color: "#8C8C8C",
            fontSize: 13,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#eee",
            },
          },
        },
        series: [
          {
            name: "Placeholder",
            type: "bar",
            stack: "Total",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            animation: false,
            label: {
              show: true,
              fontSize: 16,
              color: "#2A4DCF",
              position: "right",
              distance: -50,
            },
            barWidth: 30,
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: optionData.Placeholder,
          },
          {
            name: "Income",
            type: "bar",
            stack: "Total",
            itemStyle: {
              borderColor: "#FFAD01",
              color: "#FFAD01",
            },
            label: {
              show: true,
              fontSize: 16,
              distance: 20,
              color: "#2A4DCF",
              position: "right",
              formatter: function (params) {
                return optionData.Placeholder[params.dataIndex] + params.value;
              },
            },
            data: optionData.Income,
          },
        ],
      });
      myChart.setOption(option);
      // 动画一个个的刷数据
      let refreshIdx = 1;
      let timer = setInterval(() => {
        if (refreshIdx >= optionData.Placeholder2.length) {
          clearInterval(timer);
        } else {
          optionData.label[refreshIdx]=optionData.label2[refreshIdx];
          optionData.Placeholder.push(optionData.Placeholder2[refreshIdx]);
          optionData.Income.push(optionData.Income2[refreshIdx]);
          myChart.setOption({
            yAxis:{
              data: optionData.label,
            },
            series: [
              {
                name: "Placeholder",
                type: "bar",
                data: optionData.Placeholder,
              },
              {
                name: "Income",
                type: "bar",
                data: optionData.Income,
              },
            ],
          });
          refreshIdx++;
        }
      }, 300);

    },
  },
};
</script>
<style scoped lang="less">
@import "./one.less";
</style>

